import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase';

// Components
import Button from '../../components/button';
import PasswordInput from '../../components/passwordInput/PasswordInput.jsx';

// Sass
import './ChangePassword.scss';

export default function ChangePassword() {
  const [email, setEmail] = useState('');
  const [oldPW, setOldPW] = useState('');
  const [newPW, setNewPW] = useState('');
  const [newPWAgaing, setNewPWAgaing] = useState('');
  const [errorCode, setErrorCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/login');
      }
    });
    return unsubscribe;
  }, [navigate]);

  const changePassword = async (e) => {
    e.preventDefault();

    try {
      await auth.signInWithEmailAndPassword(email, oldPW);

      if (newPW === newPWAgaing) {
        const user = auth.currentUser;
        await user.updatePassword(newPW);
        window.alert(
          'Lykilorði hefur nú verið breytt. Aðgangurinn þinn verður skráður út og þú getur nú skráð þig inn afur með nýju lykilorði.',
        );
        await auth.signOut();
        navigate('/');
      } else {
        setErrorCode('no match');
      }
    } catch (error) {
      setErrorCode(error.code);
    }
  };

  useEffect(() => {
    if (errorCode) {
      switch (errorCode) {
        case 'auth/weak-password':
          setErrorMessage('Lykilorð verður að vera í það minnsta 6 stafa langt.');
          break;
        case 'no match':
          setErrorMessage('"Nýtt lykilorð" og "Nýtt lykilorð aftur" eru ekki eins.');
          break;
        case 'auth/wrong-password':
        case 'auth/invalid-email':
          setErrorMessage('Ekki rétt netfang eða lykilorð.');
          break;
        default:
          setErrorMessage('Villa kom upp, vinsamlegast reynið aftur');
          break;
      }
    }
  }, [errorCode]);

  const passwordCB = (who, value) => {
    if (who === 'nuverandiLykilord') {
      setOldPW(value);
    } else if (who === 'nyttLykilord') {
      setNewPW(value);
    } else if (who === 'nyttLykilordAftur') {
      setNewPWAgaing(value);
    }
  };

  return (
    <div className='changePassword col col-4 offset-col-4 col-md-8 offset-col-md-2'>
      <h2>Breyta lykilorði</h2>
      <p className='incorrect'>{errorMessage}</p>
      <form>
        <label htmlFor='email'>Netfang</label>
        <input
          id='email'
          type='email'
          placeholder='Netfang'
          onChange={(e) => {
            setEmail(e.target.value);
          }}></input>
        <PasswordInput
          text='Núverandi lykilorð'
          htmlFor='nuverandiLykilord'
          passwordCB={passwordCB}
          placeholder='Núverandi lykilorð'
        />
        <PasswordInput
          text='Nýtt lykilorð'
          htmlFor='nyttLykilord'
          passwordCB={passwordCB}
          placeholder='Nýtt lykilorð'
        />
        <PasswordInput
          text='Nýtt lykilorð aftur'
          htmlFor='nyttLykilordAftur'
          passwordCB={passwordCB}
          placeholder='Nýtt lykilorð aftur'
        />
        <Button children='Breyta' onClick={changePassword} />
      </form>
    </div>
  );
}
